<template>
  <v-expansion-panel
  >
    <v-expansion-panel-header>
      Dtouch
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-autocomplete
        v-model="selectedPermissions"
        :items="permissions"
        outlined
        dense
        chips
        small-chips
        label="Permisos"
        multiple
        item-text="name"
        item-value="id"
        @change="handleChange"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import constants from '@/constants/dtouch'
export default {
  props: {
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    selectedPermissions: null,
  }),
  computed: {
    permissions () {
      return constants.userPermission
    },
  },
  methods: {
    handleChange () {
      this.onChange({
        dtouchPermissions: this.selectedPermissions
      })
    },
  }
}
</script>
